input[type=text],
input[type=email],
input[type=url],
input[type=search],
input[type=password],
textarea,
select,
.dropdown>button {
  display: block;
  background: white !important;
  text-align: left;
  border: solid 1px gs-color(gs-darkblue);
  border-radius: 10px;
  padding: 0.7rem 0.5rem;
  width: 100%;
  color: black;

  &::placeholder {
    color: gs-color(gs-dark-grey);
  }
}

// NOTE: needs to be an SVG Background, because `input` and `select` elements
//       can not have an `::after` pseudo element.
select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI3LjguMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkViZW5lXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGwtcnVsZTpldmVub2RkO2NsaXAtcnVsZTpldmVub2RkO2ZpbGw6I0QyMDA2NDt9Cjwvc3R5bGU+CjxwYXRoIGlkPSJQZmFkXzE1ODJfMDAwMDAwMzEyMDkxMjEyNTMyMTU4MTUxNTAwMDAwMDYzMTQ0MjY0OTMwMTQ2NTEwNjlfIiBjbGFzcz0ic3QwIiBkPSJNMjIzLjMsMzJjMC0xNy43LDE0LjMtMzIsMzItMzIKCXMzMiwxNC4zLDMyLDMydjM3MC43bDEzNy40LTEzNy40YzEyLTEzLDMyLjMtMTMuOSw0NS4zLTEuOXMxMy45LDMyLjMsMS45LDQ1LjNjLTAuNiwwLjctMS4zLDEuMy0xLjksMS45bC0xOTIsMTkyCgljLTEyLjUsMTIuNS0zMi43LDEyLjUtNDUuMiwwbDAsMGwwLDBsLTE5Mi0xOTJjLTEyLjItMTIuOC0xMS44LTMzLjEsMC45LTQ1LjNjMTIuNC0xMS45LDMyLTExLjksNDQuNCwwbDEzNy40LDEzNy40TDIyMy4zLDMyeiIvPgo8L3N2Zz4K") no-repeat white !important;
  background-size: 20px !important;
  background-position: center right 1rem !important;
}

.dropdown {
  z-index: 10;

  button {
    z-index: 12;
    @include arrow-down-after(1em, gs-color(gs-pink), 0.5em, right);
    padding-right: calc(2.5rem);
    overflow: hidden;

    &:hover {
      background: white;
      border: solid 1px gs-color(gs-darkblue);
      color:black;
    }
  }

  ul.dropdown-menu {
    width: 100%;
    border: solid 1px gs-color(gs-darkblue);
    border-radius: 10px;
    z-index: 11;

    &[data-popper-placement="top-start"] {
      margin-bottom: -2rem !important;
      padding-bottom: 2rem;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &[data-popper-placement="bottom-start"] {
      margin-top: -2rem !important;
      padding-top: 2rem;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    li {
      a {
        padding: 0.5rem 1rem;

        &:hover {
          background: gs-color(gs-default-grey);
          color: black;
        }

        // Remove default link underline
        box-shadow: none;
      }
    }
  }
}

label {
  margin-bottom: 0.3rem;

  a {
    color: gs-color(gs-pink) !important;

    &:hover {
      text-decoration: underline;
    }

    // Remove default link undline
    box-shadow: none !important;
  }
}

$size: 28px;

.checkbox {
  display: block;
  position: relative;
  padding-left: calc(0.7rem + #{$size});
  cursor: pointer;
  //font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  // hide default rendering
  /*
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}
    */

  input {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: $size;
    width: $size;
    background-color: white;
    border: solid 1px gs-color(gs-darkblue);
    border-radius: 3px;
    cursor: pointer;

    &::after {
      content: $gesis-icon-checkmark;
      font-size: 0.8em;
      font-family: $gesis-icons-font;
      color: white;
      position: absolute;
      display: none;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  input:checked {
    background-color: gs-color(gs-darkblue);
  }

  input:checked:after {
    display: block;
  }

}

$size: 28px;

.radio {
  display: block;
  position: relative;
  padding-left: calc(0.7rem + #{$size});
  cursor: pointer;
  //font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /*
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}
    */

  input {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: $size;
    width: $size;
    background-color: white;
    border: solid 1px gs-color(gs-darkblue);
    border-radius: calc(2 * #{$size});

    &::after {
      content: "";
      position: absolute;
      display: none;

      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: calc(#{$size} * 0.55);
      height: calc(#{$size} * 0.55);
      border-radius: calc(2 * #{$size});
      background: gs-color(gs-darkblue);
    }
  }

  input:checked:after {
    display: block;
  }
}


input[type=submit] {
  @include gesis-button;
}

.tx-powermail {
  .container-fluid {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  form {
    >fieldset {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: calc(var(--gs--space--grid-gutter-width)* .5* 2);


      @include lg {
        grid-template-columns: 1fr;
      }
    

      >div {
        &.layout1 {
          grid-column: 1 / -1;
        }

        .powermail_captchaimage {
          border-radius: 10px;
          margin-top: 0.7rem;
        }
      }
    }

    .powermail_legend {
      float: inherit;
      padding-top: 40px;
      padding-bottom: 20px;
    }
  }
}
