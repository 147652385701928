// Imported via _text.scss

h1, .h1 {
	font-size: gs-font-size(heading--primary);
	margin-bottom: 3.9rem;
}

h2, .h2 {
	font-size: gs-font-size(heading--secondary);
	margin-bottom: 3.9rem;
}

h3, .h3 {
	font-size: gs-font-size(heading--tertiary);
}

h1,
h2,
h3,
.h1,
.h2,
.h3 {
	font-family: $headline-font;
	font-weight: 500;
	color: gs-var(headline--color);
}

.text-white {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: white;
	}
}

@include xl {
	h1, h2, .h1, .h2 {
		margin-bottom: 2.8rem;
	}
}