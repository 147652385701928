table {
	background-color: #fff;
    width: 100%;
	
	td,
	th {
		&:not(:first-of-type) {
			border-collapse: separate;
			border-left: 2px solid #fff;
			margin-left: 2px;
		}
	}

	thead {
		background-color: gs-var(table--head-color);
		color: #fff;

		td,
		th {
			padding: 1rem 1.5rem;
		}
	}

	tbody {
		tr {
			border-bottom: 2px solid gs-color(gs-accent-grey);

		}
		td {
			padding: 1.5rem;
			background-color: #fff;
			vertical-align: top;
		}
	}

	&.sortable {
		.sortable-table-header {
			.sortable-table-header--inner {
				display: flex;
				align-items: center;

				&:after {
					content: "";
					margin-left: .5rem;
					display: inline-block;
					width: gs-var(table--sort-indicator-size);
					height: gs-var(table--sort-indicator-size);
					border-radius: calc(2 * gs-var(table--sort-indicator-size));
					background-color: rgba(255, 255, 255, .37);
					background-repeat: no-repeat;
					background-size: calc(gs-var(table--sort-indicator-size) / 2.5);
					background-position: center;
				}
			}
			
			&[aria-sort="none"] {
				.sortable-table-header--inner:after {
					background-image: get-sort-icon($icon-type: "unsorted");
				}
			}
			&[aria-sort="ascending"] {
				.sortable-table-header--inner:after {
					background-image: get-sort-icon($icon-type: "asc");
				}
			}
			&[aria-sort="descending"] {
				.sortable-table-header--inner:after {
					background-image: get-sort-icon($icon-type: "desc");
				}
			}
		}
	}
}

.dataTables_wrapper.dt-gesisweb {
  .dataTables_filter {
    input {
      margin-left: 0.5rem;
    }
  }

  // adapted from
  // https://github.com/DataTables/Dist-DataTables-Bootstrap5/blob/1.13.4/css/dataTables.bootstrap5.css
  table.dataTable {
    thead {
      > tr {
        > th, > td {


          &.sorting {
            cursor: pointer;
            position: relative;
            padding-right: 26px; // extra space for sorting icons

            &:focus-visible {
              outline-color: var(--gs--color--gs-berry);
            }

            // both sorting icons ascending/up and descending/down
            &::before, &::after {
              position: absolute;
              display: block;
              right: 10px;
              line-height: 9px;
              font-size: 0.8em;
              text-shadow: // white outline on sorting icons
                white 0px 0px 1px,
                white 0px 0px 1px,
                white 0px 0px 1px,
                white 0px 0px 1px,
                white 0px 0px 1px,
                white 0px 0px 1px;
            }

            // sorting icon ascending/up only
            &::before {
              bottom: 55%;
              // content: "▲";
               //content: "▲"/"";
              font-family: bootstrap-icons;
              content: '\f235'; // similar to '▲'
            }

            // sorting icon descending/down only
            &::after {
              top: 55%;
              // content: "▼";
              // content: "▼"/"";
              font-family: bootstrap-icons;
              content: '\f229'; // similar to '▼'
            }
          } // .sorting


          // if ascending/descending is active, color the corresponding active icon
          &.sorting_asc::before, &.sorting_desc::after {
            color: var(--gs--color--gs-berry);
          }


          &.sorting_asc_disabled, &.sorting_desc_disabled {
            &::before, &::after {
              display: none;
            }
          }



        } // > th, > td
      } // > tr
    } // thead
  } // table.dataTable
} // .dataTables_wrapper.dt-gesisweb

